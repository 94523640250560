<template>
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>
  <div class="navBar">
    <div class="navBar__container">
      <router-link to="/">
        <SuperFarmIcon />
      </router-link>
      <div class="navBar__menu">
        <router-link to="/drops">Drops</router-link>
        <router-link to="/farms">Farms</router-link>
        <a href="https://superstarter.co/">SuperStarter</a>
        <Button>
          Connect Wallet
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
//Component Imports.
import Button from '/src/components/ui/Button';
import WalletModal from '/src/components/common/WalletModal';

// Icon imports.
import SuperFarmIcon from '/src/components/icons/SuperFarmIcon';

export default {
  components: {
    Button,
    WalletModal,
    SuperFarmIcon
  }
};
</script>

<style scoped lang="scss">
.navBar {
  position: sticky;
  top: 0px;
  z-index: 4;
  display: flex;
  height: 60px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .navBar__container {
    position: relative;
    display: flex;
    max-width: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }

  .navBar__menu {
    display: flex;
    align-items: center;
  }
}
</style>
