import {sortByOptions} from '@/components/common/filters'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
    isLoading: false,
    items: [],
    filters: {
        status: [],
        price: {
            from: '',
            to: ''
        },
        currency: null,
        collections: [],
        sort_by: sortByOptions[0]
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
