'use strict';

import * as LDClient from 'launchdarkly-js-client-sdk';


export default {
  install: (app, options) => {
    const client = LDClient.initialize(options.client_id, options.user);
    app.config.globalProperties.$flags = client;
  }
};
