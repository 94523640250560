const mockItems = [
    {
        id: '1',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb935738e434e6050ead7_nft-2.jpg',
            state: 'transferred',
            likes: 1200
        }
    },
    {
        id: '2',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb938dbb61df64aa0a583_nft.jpg',
            state: 'offered',
            likes: 1200
        }
    },
    {
        id: '3',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a0d743a3b854604a2_nft.png',
            state: 'on_auction',
            likes: 1200
        }
    },
    {
        id: '4',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb9362793fe221848f607_nft-1.jpg',
            state: 'on_sale',
            likes: 1200
        }
    },
    {
        id: '5',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a64f683fa80daa6fa_nft-1.png',
            state: 'purchased',
            likes: 1200
        }
    },
    {
        id: '6',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcd4091bee8578208f2_nft-2.png',
            state: 'sold',
            likes: 1200
        }
    },
    {
        id: '7',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcb04931f63b703996e_nft-5.jpg',
            state: 'bid',
            likes: 1200
        }
    },
    {
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcfe183a2f741ea9f1f_nft-3.png',
            state: 'accepted',
            likes: 1200
        }
    },
    {
        id: '8',
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcfe183a2f741ea9f1f_nft-3.png',
            state: 'collection',
            likes: 1200
        }
    }
]

export default {
    async getItems({commit, state}) {
        commit('setLoading', true)

        if (state.items.length !== 0) {
            commit('setItems', [])
        }

        const data = await new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    items: mockItems,
                })
            }, 600)
        })

        commit('setItems', data.items)

        commit('setLoading', false)
    },
    async setExactFilter({commit, dispatch}, payload) {
        commit('setItems', [])

        commit('setExactFilter', payload)

        await dispatch('getItems')
    },
    async setAllFilters({commit, dispatch}, payload) {
        commit('setItems', [])

        commit('setAllFilters', payload)

        await dispatch('getItems')
    }
}
