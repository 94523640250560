import { authService, ethersService } from '../../services';
import * as ethers from 'ethers';
import tokenStorage from '@/services/token-storage.service.js';
import auth from '.';

export default {
  async login({ commit, dispatch }, address) {
    try {
      const { sessionId, otp } = await authService.Auth.login(address);

      const domain = { name: 'SuperFarm Authentication', chainId: 1, version: '1' };
      const types = { TypeData: [{ name: 'otp', type: 'uint32' }] };
      let provider = await ethersService.getProvider();
      let signer = await provider.getSigner();
      let code = await signer._signTypedData(domain, types, {otp: otp});
      console.log(code);

      const { accessToken, refreshToken } = await authService.Auth.verifyOTP(sessionId, code);

      tokenStorage.setAccessToken(accessToken);
      tokenStorage.setRefreshToken(refreshToken);

      commit('loginSuccess', { accessToken, refreshToken });
    } catch (err) {
      console.error(err);
      dispatch('loginFailure', err);
    }
  },
  logout({commit}) {
    tokenStorage.removeAccessToken();
    tokenStorage.removeRefreshToken()
    commit('logout');
  },
  async refreshToken({ commit, dispatch, state }, token) {
    const {accessToken, refreshToken } = await authService.Auth.refreshToken(token);

    tokenStorage.setAccessToken(accessToken);
    tokenStorage.setRefreshToken(refreshToken);
  },
  loginFailure({ dispatch }, error) {
    dispatch('alert/error', { message: error, duration: 5000  }, { root: true });
  }
};
