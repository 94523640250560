import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import tokenStorage from "@/services/token-storage.service"

const state = {
  user: {
    accessToken: tokenStorage.getAccessToken() | '',
    refreshToken: tokenStorage.getRefreshToken() | ''
  },
  status: {
    loggingIn: false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}