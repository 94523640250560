import store from '@/store'

export class ErrorApiHandler {
    showResponseError = error => {
        const message = this.serverCodeToNotificationText(error.response?.data.code)
            || (error.response?.data.message || 'Something went wrong')

        store.dispatch('alert/error', {message, duration: 5000})
    }

    serverCodeToNotificationText = code => {
        switch (code) {
            default:
                return ''
        }
    }
}

const errorApiHandler = new ErrorApiHandler()

export default errorApiHandler