export default {
    setItems: (state, payload) => {
      state.items = payload
    },
    setLoading: (state, payload) => {
        state.isLoading = payload
    },
    setExactFilter: (state, payload) => {
        state.filters[payload.type] = payload.value
    },
    setAllFilters(state, payload) {
        Object.keys(payload).forEach(key => {
            if (payload.hasOwnProperty(key)) {
                this.commit('following/setExactFilter', {type: key, value: payload[key]})
            }
        })
    }
}