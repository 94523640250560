'use strict';

// Component imports.
import { createApp } from 'vue';
import VueFinalModal from 'vue-final-modal';
import VueTilt from 'vue-tilt.js';
import VueSmoothScroll from 'vue3-smooth-scroll';
import Nprogress from 'nprogress';
import VueZoomer from 'vue-zoomer';
import VueMyToasts from 'vue-my-toasts';
import Toast from '/src/components/common/Toast.vue';
import featureFlags from './plugins/feature-flags';

// Style imports.
import 'nprogress/nprogress.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './assets/style/main.scss';

// Application imports.
import './bootstrap';
import App from './App.vue';
import router from './router';
import store from './store';

// Global Component Imports.
import TheHeader from './layouts/MainLayout/components/TheHeader.vue';
import TheFooter from './layouts/MainLayout/components/TheFooter.vue';
import IconBase from '@/components/ui/icons/IconBase.vue';

Nprogress.configure({
  speed: 700,
  showSpinner: false,
  trickleSpeed: 250
});

// Create our application with required dependencies.
const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueFinalModal())
  .use(VueTilt)
  .use(VueSmoothScroll, {
    duration: 800,
    updateHistory: false
  })
  .use(VueZoomer)
  .use(VueMyToasts, {
    // component: BootstrapComponent,
    component: Toast,
    options: {
      width: '50vw',
      position: 'bottom-left',
      padding: '2rem'
    }
  })
  .use(featureFlags, {
    client_id: process.env.VUE_APP_LAUNCHDARKLY_CLIENT_ID,
    user: {
      key: 'anonymous'
    }
  })
  .component('TheHeader', TheHeader)
  .component('TheFooter', TheFooter)
  .component('IconBase', IconBase)
  .mount('#app');

store.$app = app;
