<template>
  <template v-if="flagsReady">
    <ThemeProvider>
      <router-view />
    </ThemeProvider>
  </template>
  <template v-else> </template>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';

import { ThemeProvider } from '@/components/utility/theme';

// Set up the default application component.
export default {
  name: 'SuperFarm',
  components: { ThemeProvider },

  // Retrieve a new copy of the alert module's state.
  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert),
      ethereum: state => state.ethers,
      visibility: state => Object.assign({}, state.visibility),
      toast: state => Object.assign({}, state.toast)
    })
  },

  data() {
    return {
      flagsReady: false
    };
  },

  // Register the listener for focus detection changes.
  created() {
    this.setup({ threshold: 10 * 60 * 1000 });

    this.$flags.on('ready', () => {
      this.flagsReady = true;
    });
  },

  // Support application-wide suspension of Ethers service polling when the
  // application loses focus. This saves on default provider requests.
  methods: {
    ...mapActions('alert', ['clear', 'warning']),
    ...mapActions('visibility', ['setup', 'cleanup']),
    ...mapActions('ethers', ['pause', 'unpause']),
    ...mapActions('auth', ['login'])
  },

  // Remove the change detection event listeners upon application cleanup.
  beforeUnmount() {
    this.cleanup();
  },

  // Watch the alert module's state for any toast notifications to show.
  watch: {
    alert: {
      deep: true,
      handler: function(newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let type = newAlert.type;
        let duration = newAlert.duration;
        let metadata = newAlert.metadata ? newAlert.metadata : {};

        if (message && type) {
          this.$toasts.base('', {
            message: message,
            type: type,
            duration: duration,
            metadata: metadata
          });
          this.lastMessage = message;
        } else {
          // TODO: dispatch a clear event.
        }
      }
    },

    // Watch the visibility module's state for reacting to page visibility
    // updates.
    visibility: {
      deep: true,
      handler: async function(newVisibility) {
        let hidden = newVisibility.hidden;
        let probablyHidden = newVisibility.probablyHidden;
        if (hidden || probablyHidden) {
          await this.pause();
        }
        if (probablyHidden) {
          await this.warning({
            message: `It seems like you've been inactive for a while. Are you still there?`,
            duration: false
          });
        }
        if (!hidden && !probablyHidden) {
          await this.unpause();
          await this.clear();
        }
      }
    }
  }
};
</script>

<style>
/* Style overrides */

/* This must be overridden because we are using vue-final-modal for the CardModal */
.vfm--overlay {
  background-color: rgba(var(--background-color-rgb), 0.3) !important;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}
</style>
