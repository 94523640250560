import axios from 'axios'

import store from '@/store'

import errorApiHandler from '@/services/errorApiHandler.service'

export default (function () {
    axios.interceptors.request.use(config => {
        // TODO Get access token form auth store.
        const accessToken = ''

        if (accessToken && !config.headers.hasOwnProperty('Authorization')) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
    })

    axios.interceptors.response.use(response => response, error => {
        if (axios.isCancel(error)) {
            return
        }

        if (!error.response) {
            store.dispatch('alert/error',{
                message: 'Whoops! There seems to be a problem with a network connection. Please try again later.',
                duration: 5000
            })
        } else {
            errorApiHandler.showResponseError(error)

            return Promise.reject(error)
        }
    })
}())