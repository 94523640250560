'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';
import { account } from './account.module';
import ethers from './ethers';
import claim from './claim';
import tokens from "./tokens";
import farms from "./farms";
import shops from "./shops";
import items from "./items";
import itemModal from "./itemModal";
import visibility from "./visibility";
import pool from "./pool";
import notifications from "./notifications"
import profile from "./profile"
import searchResults from "./search-results"
import activity from "./activity"
import pools from "./pools";
import theme from './theme';
import auction from './auction';
import tokenPrices from './token-prices';
import drops from './drops';
import trade from './trade';
import collection from './collection';
import following from './following';
import modal from './modal';
import deployFarm from './deploy-farm';
import auth from './auth'

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    account,
    auth,
    ethers,
    claim,
    drops,
    tokens,
    farms,
    shops,
    items,
    itemModal,
    visibility,
    pool,
    notifications,
    searchResults,
    activity,
    pools,
    auction,
    theme,
    tokenPrices,
    trade,
    profile,
    collection,
    following,
    modal,
    deployFarm
  }
});
