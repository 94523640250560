<template>
  <div class="footer">
    <div class="footer-container">
      <div class="links">
        <div class="socials">
          <SuperFarmIcon @click.stop="goTo(`https://www.superfarm.com/`)" />
          <!-- Telegram -->
          <div class="social-section">
            <TelegramIcon />
            <a href="https://t.me/SuperFarmDAO" target="_blank">Telegram</a>
          </div>
          <!-- Twitter -->
          <div class="social-section">
            <TwitterIcon />
            <a href="https://twitter.com/SuperFarmDAO" target="_blank">Twitter</a>
          </div>
          <!-- Medium -->
          <div class="social-section">
            <MediumIcon />
            <a href="https://medium.com/superfarm" target="_blank">Medium</a>
          </div>
        </div>
        <div class="links-section">
          <!-- <h1>Explore</h1>
        <p>My Farms</p>
        <p>View Farms</p>
        <p>NFTs</p> -->
        </div>
        <div class="links-section">
          <h1>About</h1>
          <!-- <p>Company</p>
        <p>FAQ</p>
        <p>Careers</p>
        <p>Media Kit</p> -->
          <p>
            <router-link class="" to="/drops">
              NFT Drops
            </router-link>
          </p>

          <p>
            <router-link class="" to="/farms">
              Farms
            </router-link>
          </p>

          <!-- <p><a href="https://medium.com/superfarm" target="_blank">Blogs</a></p> -->
          <p>
            <router-link class="roadmap-link" to="/roadmap">
              Roadmap
            </router-link>
          </p>
          <a href="https://www.coingecko.com/en/coins/superfarm" target="_blank">Coin Gecko</a>
        </div>
        <div class="links-section">
          <!-- <h1>Developers</h1>
        <p>GitHub</p>
        <p>Documentation</p> -->
        </div>
      </div>
      <div class="info">
        <div class="company">© 2021 SuperFarm</div>
        <div class="legal">
          <p>
            <router-link class="roadmap-link" to="/terms">
              Terms and Conditions
            </router-link>
          </p>
          <p>
            <router-link class="roadmap-link" to="/privacy">
              Privacy Policy
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Icon imports
import SuperFarmIcon from '../../../components/icons/SuperFarmIcon';
import TelegramIcon from '../../../components/icons/TelegramIcon';
import TwitterIcon from '../../../components/icons/TwitterIcon';
import MediumIcon from '../../../components/icons/MediumIcon';

// Set up the default header component.
export default {
  name: 'Footer',
  components: {
    SuperFarmIcon,
    TelegramIcon,
    TwitterIcon,
    MediumIcon
  },
  methods: {
    // Go to a different path.
    goTo(path) {
      window.open(path, '_self');
    }
  }
};
</script>

<style scoped>
.footer {
  display: flex;
  color: var(--text-color);
  background-color: var(--background-color);
  font-size: 14px;
  line-height: 1.3em;
  padding: 60px 30px;
  max-width: 1600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.links > * {
  margin-right: 7vw;
}

.links-section {
  text-align: left;
}

.links-section > * {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section a {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.links-section > h1 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.socials {
  display: flex;
  flex-direction: column;
}

.socials > img {
  width: 130px;
}

.socials > * {
  margin-bottom: 20px;
}

.social-section {
  display: flex;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.social-section svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.info {
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.legal {
  display: flex;
}

.legal > * {
  margin-left: 10px;
  text-decoration: underline;
}

.legal > *:hover {
  cursor: pointer;
}

@media (max-width: 450px) {
  .legal {
    display: none;
  }

  .links-section {
    display: none;
  }
}

@media (max-width: 800px) {
  .links > * {
  }

  .links {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
